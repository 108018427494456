@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.upload-form_large {
  width: 100%;
  min-height: 85vh;
  display: flex;
  top: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.head_right_title {
  font-weight: 400;
  font-size: 1.2rem;
}

.head_form_wrapper {
  top: 200px;
}

.head_form_wrapper {
  z-index: 1;
  padding-right: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 38%;
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}

.bounce-in {
  opacity: 100%;
  transform: translateY(200px);
  -webkit-animation-name: formFromBottom;
          animation-name: formFromBottom;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
  opacity: 0;
}

@-webkit-keyframes formFromBottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes formFromBottom {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.head_form_wrapper_secondary {
  z-index: 1;
  padding-right: 3%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* width: 40%; */
  width: auto;
  position: relative;
  margin-top: 40px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.head_form {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 400px;
  align-items: center;
  justify-content: center;
  /* z-index: 100; */
}

/* .head_form_heading {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 1.4rem;
	color: #778;
} */

.head_form_input {
  border: none;
  /* border-bottom: 1px solid #545454; */

  margin-bottom: 7px;
  /* background-color: #fed8cb; */
  background-color: transparent;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.48);
  color: #000;
  padding: 25px;
  outline: none;
  display: flex;
  width: 100%;
  font-size: 14px;
  font-family: sans-serif;
  padding-bottom: 2px;
}

.head_form_input:-webkit-autofill {
  background-color: transparent;
}

.head_form_file_input_wrapper {
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.48);
}

.head_form_file_input {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  width: 100%;
  margin-bottom: 7px;
  padding: 25px;
  padding-bottom: 2px;
  text-transform: capitalize;
  border-bottom: 2px dashed rgba(0, 0, 0, 0.48);
  font-size: 12px;
  font-family: sans-serif;
}

.input_form_uploaded {
  flex-direction: column !important;
}

.head_form_consent {
  font-size: 12px;
  font-family: sans-serif;
  margin-bottom: 4px;
  line-height: 1.3;
}

.head_form_file_name {
  margin-bottom: 0;
  line-height: 1.5;
}

#head_form_browse_file {
  display: none;
}

.head_form_input::-webkit-input-placeholder, .head_form_textarea::-webkit-input-placeholder {
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.head_form_input:-ms-input-placeholder, .head_form_textarea:-ms-input-placeholder {
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.head_form_input::-ms-input-placeholder, .head_form_textarea::-ms-input-placeholder {
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.head_form_input::placeholder,
.head_form_textarea::placeholder {
  color: rgb(0, 0, 0);
  text-transform: capitalize;
}

.head_form_file_input_text {
  margin-right: 4px;
  margin-left: 4px;
  color: #000;
}

.head_form_file_input_btn {
  border: none;
  background-color: transparent;
  text-decoration: underline;
  color: #041577;
  border-radius: 1px;
  cursor: pointer;
}

.head_form_file_input_btn:hover {
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
}

.head_form_textarea {
  border: none;
  /* border-bottom: 1px solid #545454; */
  margin-bottom: 7px;
  color: #000;
  padding: 25px;
  padding-bottom: 2px;
  outline: none;
  height: 40px;
  background: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.48);
  font-size: 14px;
  width: 100%;
  font-family: sans-serif;
  line-height: 1;
}

input[type="checkbox"]:after {
  background: #91a288;
  content: "";
  position: absolute;
  cursor: pointer;
  height: 15px;
  width: 15px;
  border: 1px solid black;
}

input[type="checkbox"]:checked:after {
  background: #91a288;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "\2714";
  color: #fff;
}

.head_form_terms_conditions_text {
  font-size: 12px;
  font-family: sans-serif;
  padding-left: 5px;
}

.head_form_terms_conditions_text_link {
  color: #041577 !important;
}

.head_form_submit_btn {
  border: none;
  background: linear-gradient(#557cc5, #041577);
  cursor: pointer;
  color: white;
  height: 35px;
  width: 136px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  border-radius: 3px;
  outline: none;
  margin-top: 30px;
}

.head_form_submit_btn_pending {
  border: none;
  background-color: #fe6e63;
  cursor: pointer;
  color: white;
  height: 35px;
  width: 136px;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 100px;
  outline: none;
}

.head_form_submit_btn_submitted {
  content: "Submitting...";
  border: none;
  background-color: #057c0f;
  cursor: pointer;
  color: white;
  height: 35px;
  width: 136px;
  text-transform: uppercase;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 600;
  border-radius: 100px;
  outline: none;
}

.head_form_reupload_btn {
  border: none;
  background-color: #fe6e63;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 12px;
  outline: none;
}

.form_success {
  padding-left: 20px;
  padding-right: 20px;
  width: 30%;
  z-index: 5;
  text-align: center;
}

.form_success_text {
  font-size: 18px;
  color: rgb(0, 0, 0);
  font-family: sans-serif;
}

.form_success_secondary_text {
  font-size: 14px;
  font-family: sans-serif;
}

.form_success_thank {
  font-family: Athiti;
  color: #fe6e63;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.form_success_email {
  color: #fe6e63 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

.how-it-works {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 35px;
  padding-bottom: 25px;
  margin-bottom: 50px;
}

.how-it-works_title {
  font-family: sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 80px;
}

.how-it-works_steps {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.how-it-works_step {
  display: flex;
  width: 23%;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
}

.how-it-works_step:nth-child(4) {
  width: calc(23% - 30px);
}

.how-it-works_step_content:hover {
  box-shadow: 0px 5px 20px 1px rgb(109, 109, 109);
}

.how-it-works_step_content {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: flex-start;
  border: 2px solid #eeecec;
  padding: 20px;
  padding-top: 25%;
  margin-right: 7px;
  transition: box-shadow 0.2s ease;
  height: 320px;
  width: 100%;
}

.how-it-works_step_image {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.how-it-works_step_description {
  text-align: center;
}

.how-it-works-btn {
  cursor: pointer;
  border: none;
  height: 50px;
  width: 150px;
  background-color: #fe6e63;
  font-family: sans-serif;
  margin: 80px auto 0px auto;
}

.Typewriter {
  display: inline;
}

.download_plugin_btn {
  border: 1px #fff solid;
  background-color: #343434;
  /* position: absolute; */
  /* font-size: 14px !important; */
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: white !important;
  text-decoration: none;
  z-index: 2;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  /* opacity: 0; */
  text-transform: uppercase;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  margin: auto;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Athiti", sans-serif !important;
}

.download_plugin_btn:hover {
  /* background-color: #fe6e63; */
  color: white !important;
}

@media screen and (max-width: 1200px) {
  .how-it-works_title {
    margin-top: 40px;
  }

  .head_form_wrapper {
    /* display: none; */
  }

  .head_form_wrapper_secondary {
    display: flex;

    margin-left: auto;
    margin-right: auto;
  }

  .how-it-works_steps {
    flex-direction: column;
    align-items: center;
  }

  .how-it-works_step {
    width: 90% !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .how-it-works_step_content {
    width: 100%;
    padding-top: 10%;
  }

  .how-it-works_step img:nth-child(2) {
    transform: rotate(90deg);
  }

  .vision_wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .head_form_wrapper {
    padding-right: 0%;
  }

  .landing_head {
    flex-direction: column;
    align-items: center;
    justify-items: center;
    padding: 0;
    margin-bottom: 0px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }

  .landing_head_items {
    flex-direction: column;
    align-items: center;
  }

  .carousel {
    margin-bottom: 40px;
  }

  .magnifier {
    position: relative !important;
    /* height: 97vh !important; */
    height: auto !important;
    min-height: 80vh !important;
    object-fit: cover;
  }

  .magnifier-ie {
  }

  .magnifier-image {
    height: auto;
    width: auto;
    min-height: 80vh !important;
    object-fit: cover;
  }

  .coming-soon {
    margin-bottom: 0px;
  }

  .magnifier {
    display: none !important;
  }

  .head_left_title {
    font-size: 1.5rem;
    border-bottom: 0.7px solid rgb(151, 151, 151);
    width: 100%;
    margin-bottom: 0;
    padding-bottom: 1.45rem;
    color: #fe6e63;
  }

  .coming-soon {
    font-size: 1.5rem;
  }

  .vision_title {
    font-size: 1.5rem;
  }

  .head_left_title {
    display: none;
  }

  .small_screen_head_title {
    display: block;
    width: 100%;
  }

  .vision_wrapper_pic {
    width: 60%;
  }

  .form_success {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .head_form_textarea {
    padding: 10px;
  }
  .head_form_input {
    padding: 10px;
  }
  .head_form_file_input {
    padding: 10px;
  }
  .head_form {
    width: 300px;
  }
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    position: unset;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    position: unset;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    position: unset;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    position: unset;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

#portfolio-wrapper {
  /* margin-top: 40px; */
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#portfolio_items-wrapper {
  display: flex;
  flex-basis: 33.333333%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin-top: 30px; */
  display: grid;
  grid-auto-rows: 220px;
  grid-template-columns: repeat(3, 270px);
  grid-gap: 5px;
}

.portfolio-title {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.portfolio-item {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.portfolio-item__details {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Poppins, sans-serif;
  position: absolute;
  bottom: -100%;
  -webkit-animation-name: appearFromBottom;
          animation-name: appearFromBottom;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.portfolio-item:hover .portfolio-item__details {
  opacity: 1;
}

.portfolio-item .item {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  position: absolute;
  bottom: -100%;
  -webkit-animation-name: appearFromBottom;
          animation-name: appearFromBottom;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.portfolio-item .item:hover {
  transform: scale(1.1);
}

.arrows-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}

.right-arrow {
  position: absolute;
  right: 5%;
  top: 50%;
  bottom: 50%;
  height: 100px;
  width: 100px;
  background-image: url(/static/media/right-arrow.287f1413.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.left-arrow {
  position: absolute;
  left: 5%;
  top: 50%;
  bottom: 50%;
  height: 100px;
  width: 100px;
  background-image: url(/static/media/left-arrow.eaf311b7.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

@-webkit-keyframes appearFromBottom {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}

@keyframes appearFromBottom {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}

#product-view {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.product-view__obj-view {
  opacity: 0;
  /* position: absolute; */
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
  -webkit-animation-duration: 450ms;
          animation-duration: 450ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}

.product-view__content-view__title {
  font-family: "Poppins", sans-serif;
  -webkit-animation-name: bounceIn;
          animation-name: bounceIn;
  -webkit-animation-duration: 450ms;
          animation-duration: 450ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 100ms;
          animation-delay: 100ms;
}

.product-view__content-view__description {
  font-family: sans-serif;
  padding-right: 40px;
}

.product-view__content-view {
  flex: 1 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scene canvas {
  outline: none;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    position: unset;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    position: unset;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    position: unset;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    position: unset;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.nav-wrapper {
  padding: 5px;
  /* background-color: #fff; */
  font-family: "Poppins", sans-serif;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2px solid #000;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.nav-logo {
  text-transform: uppercase;
  border: 3px solid #000;
  padding: 3px;
  padding-left: 12px;
  padding-right: 12px;
}

.nav-links {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-link {
  text-transform: uppercase;
  transition: color 0.1s ease-in-out;
  margin-right: 40px;
  text-decoration: none;
  color: #000;
  color: initial;
}

.nav-link:hover {
  color: #041577;
}

@media screen and (max-width: 550px) {
  .nav-logo {
    font-size: 1rem;
  }

  .nav-link {
    font-size: 0.8rem;
    margin-right: 15px;
  }

  .nav-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#contact_wrapper {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  position: relative;
  overflow: hidden;
  min-height: 85vh;
  width: 100%;
}

.contact_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  width: 400px;
  margin-top: 80px;
  opacity: 0;
  position: absolute;
  bottom: -20%;
  -webkit-animation-name: footerFromBottom;
          animation-name: footerFromBottom;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes footerFromBottom {
  100% {
    opacity: 1;
    bottom: 25%;
  }
}

@keyframes footerFromBottom {
  100% {
    opacity: 1;
    bottom: 25%;
  }
}

.contact_title {
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 50px;
}

.contact_input {
  padding: 10px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.48);
  outline: none;
  font-size: 1rem;
}

.contact_textarea {
  padding: 10px;
  font-family: "Poppins", sans-serif;
  height: 100px;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.48);
  outline: none;
  font-size: 1rem;
}

.contact_btn {
  border: none;
  background: linear-gradient(#557cc5, #041577);
  cursor: pointer;
  color: white;
  height: 35px;
  width: 136px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  border-radius: 3px;
  outline: none;
  margin-top: 30px;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    position: unset;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    position: unset;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    position: unset;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    position: unset;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

.app {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  background: linear-gradient(45deg, #fff, #fee684, #517b8b);
}

.main-content {
  display: flex;
  flex: 5 1;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.main_text-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
}

.main_text {
  font-size: 4.6rem;
}

.main_text span:nth-child(1) {
  font-size: 8rem;
}

.main_text span:last-child {
  font-size: 4.8rem;
}

.main_form-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main_request-btn {
  border: none;
  background: linear-gradient(#557cc5, #041577);
  cursor: pointer;
  color: white;
  height: 55px;
  width: 200px;
  /* text-transform: uppercase; */
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  border-radius: 3px;
  outline: none;
  margin-top: 30px;
  z-index: 1;
  opacity: 1;
  position: relative;
  transition: opacity 100ms ease-in-out, position 200ms ease-in-out;
  margin-right: 70px;
  /* margin-left: calc((38%-200px) / 2);
  margin-right: calc((38%-200px) / 2); */
}

.hide-it {
  opacity: 0 !important;
  position: absolute !important;
}

.footer_text {
  font-size: 1.5rem;
  display: flex;
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1200px) {
  .main_text {
    font-size: 3.1rem;
  }

  .main_text span:nth-child(1) {
    font-size: 5.4rem;
  }

  .main_text span:last-child {
    font-size: 3.25rem;
  }
}

@media screen and (max-width: 1000px) {
  .app {
    overflow-y: unset;
  }

  .main-content {
    flex-direction: column;
  }

  .main_request-btn {
    margin: 0;
  }

  .main_text-wrapper {
    width: 100%;
  }

  .main_form-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .main_text {
    font-size: 2.5rem;
  }

  .main_text span:nth-child(1) {
    font-size: 4.4rem;
  }

  .main_text span:last-child {
    font-size: 2.6rem;
  }

  .footer_text {
    font-size: 1.2rem;
  }

  .footer_text img {
    height: 30px;
  }
}

