.app {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  background: linear-gradient(45deg, #fff, #fee684, #517b8b);
}

.main-content {
  display: flex;
  flex: 5;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.main_text-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
}

.main_text {
  font-size: 4.6rem;
}

.main_text span:nth-child(1) {
  font-size: 8rem;
}

.main_text span:last-child {
  font-size: 4.8rem;
}

.main_form-wrapper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.main_request-btn {
  border: none;
  background: linear-gradient(#557cc5, #041577);
  cursor: pointer;
  color: white;
  height: 55px;
  width: 200px;
  /* text-transform: uppercase; */
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  border-radius: 3px;
  outline: none;
  margin-top: 30px;
  z-index: 1;
  opacity: 1;
  position: relative;
  transition: opacity 100ms ease-in-out, position 200ms ease-in-out;
  margin-right: 70px;
  /* margin-left: calc((38%-200px) / 2);
  margin-right: calc((38%-200px) / 2); */
}

.hide-it {
  opacity: 0 !important;
  position: absolute !important;
}

.footer_text {
  font-size: 1.5rem;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1200px) {
  .main_text {
    font-size: 3.1rem;
  }

  .main_text span:nth-child(1) {
    font-size: 5.4rem;
  }

  .main_text span:last-child {
    font-size: 3.25rem;
  }
}

@media screen and (max-width: 1000px) {
  .app {
    overflow-y: unset;
  }

  .main-content {
    flex-direction: column;
  }

  .main_request-btn {
    margin: 0;
  }

  .main_text-wrapper {
    width: 100%;
  }

  .main_form-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .main_text {
    font-size: 2.5rem;
  }

  .main_text span:nth-child(1) {
    font-size: 4.4rem;
  }

  .main_text span:last-child {
    font-size: 2.6rem;
  }

  .footer_text {
    font-size: 1.2rem;
  }

  .footer_text img {
    height: 30px;
  }
}
