#portfolio-wrapper {
  /* margin-top: 40px; */
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#portfolio_items-wrapper {
  display: flex;
  flex-basis: 33.333333%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* margin-top: 30px; */
  display: grid;
  grid-auto-rows: 220px;
  grid-template-columns: repeat(3, 270px);
  grid-gap: 5px;
}

.portfolio-title {
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.portfolio-item {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.portfolio-item__details {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Poppins, sans-serif;
  position: absolute;
  bottom: -100%;
  animation-name: appearFromBottom;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

.portfolio-item:hover .portfolio-item__details {
  opacity: 1;
}

.portfolio-item .item {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  position: absolute;
  bottom: -100%;
  animation-name: appearFromBottom;
  animation-duration: 0.5s;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;
}

.portfolio-item .item:hover {
  transform: scale(1.1);
}

.arrows-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}

.right-arrow {
  position: absolute;
  right: 5%;
  top: 50%;
  bottom: 50%;
  height: 100px;
  width: 100px;
  background-image: url("../../images/right-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

.left-arrow {
  position: absolute;
  left: 5%;
  top: 50%;
  bottom: 50%;
  height: 100px;
  width: 100px;
  background-image: url("../../images/left-arrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  cursor: pointer;
}

@keyframes appearFromBottom {
  0% {
    bottom: -100%;
  }

  100% {
    bottom: 0;
  }
}
