@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

.nav-wrapper {
  padding: 5px;
  /* background-color: #fff; */
  font-family: "Poppins", sans-serif;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.2px solid #000;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.nav-logo {
  text-transform: uppercase;
  border: 3px solid #000;
  padding: 3px;
  padding-left: 12px;
  padding-right: 12px;
}

.nav-links {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-link {
  text-transform: uppercase;
  transition: color 0.1s ease-in-out;
  margin-right: 40px;
  text-decoration: none;
  color: initial;
}

.nav-link:hover {
  color: #041577;
}

@media screen and (max-width: 550px) {
  .nav-logo {
    font-size: 1rem;
  }

  .nav-link {
    font-size: 0.8rem;
    margin-right: 15px;
  }

  .nav-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
