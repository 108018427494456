#product-view {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.product-view__obj-view {
  opacity: 0;
  /* position: absolute; */
  animation-name: bounceIn;
  animation-duration: 450ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 100ms;
}

.product-view__content-view__title {
  font-family: "Poppins", sans-serif;
  animation-name: bounceIn;
  animation-duration: 450ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 100ms;
}

.product-view__content-view__description {
  font-family: sans-serif;
  padding-right: 40px;
}

.product-view__content-view {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.scene canvas {
  outline: none;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    position: unset;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    position: unset;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
