#contact_wrapper {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  position: relative;
  overflow: hidden;
  min-height: 85vh;
  width: 100%;
}

.contact_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 400px;
  width: 400px;
  margin-top: 80px;
  opacity: 0;
  position: absolute;
  bottom: -20%;
  animation-name: footerFromBottom;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

@keyframes footerFromBottom {
  100% {
    opacity: 1;
    bottom: 25%;
  }
}

.contact_title {
  font-family: "Poppins", sans-serif;
  text-align: center;
  margin-bottom: 50px;
}

.contact_input {
  padding: 10px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.48);
  outline: none;
  font-size: 1rem;
}

.contact_textarea {
  padding: 10px;
  font-family: "Poppins", sans-serif;
  height: 100px;
  width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 0.3px solid rgba(0, 0, 0, 0.48);
  outline: none;
  font-size: 1rem;
}

.contact_btn {
  border: none;
  background: linear-gradient(#557cc5, #041577);
  cursor: pointer;
  color: white;
  height: 35px;
  width: 136px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  border-radius: 3px;
  outline: none;
  margin-top: 30px;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    position: unset;
    transform: scale(0.3) translate3d(0, 0, 0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    position: unset;
    transform: scale(1) translate3d(0, 0, 0);
  }
}
